// Mobile.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { data } from "../../assets/dummydata";
import "./mobile.scss";

const Mobile = () => {
  const [products, setProducts] = useState(data.products);
  const [category, setCategory] = useState();
  const [lightboxImage, setLightboxImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const lightboxRef = useRef(null);
  const touchStartX = useRef(null);

  const openLightbox = (imageId) => {
    const selectedProduct = data.products.find((product) => product.id === imageId);

    if (selectedProduct) {
      const imagesArray = selectedProduct.thumbnail;
      setLightboxImage(imagesArray);
      setCurrentIndex(data.products.indexOf(selectedProduct));
    }
  };

  const nextSlide = useCallback(() => {
    if (currentIndex !== null) {
      const nextIndex = (currentIndex + 1) % products.length;
      const nextProduct = products[nextIndex];
      const nextImagesArray = nextProduct.thumbnail;

      setLightboxImage(nextImagesArray);
      setCurrentIndex(nextIndex);
    }
  }, [currentIndex, products]);

  const prevSlide = useCallback(() => {
    if (currentIndex !== null) {
      const prevIndex = (currentIndex - 1 + products.length) % products.length;
      const prevProduct = products[prevIndex];
      const prevImagesArray = prevProduct.thumbnail;

      setLightboxImage(prevImagesArray);
      setCurrentIndex(prevIndex);
    }
  }, [currentIndex, products]);

  const filterByCat = (byCat) => {
    const filteredData = data.products.filter((obj) => byCat === obj.category);
    setProducts(filteredData);
    closeLightbox(); // Close lightbox when filtering
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  useEffect(() => {
    const handleTouchStart = (e) => {
      touchStartX.current = e.touches[0]?.clientX;
    };

    const handleTouchMove = (e) => {
      if (touchStartX.current === null) return;

      const touchEndX = e.touches[0]?.clientX;
      const deltaX = touchStartX.current - touchEndX;

      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          nextSlide();
        } else {
          prevSlide();
        }

        touchStartX.current = null;
      }
    };

    const lightboxContainer = lightboxRef.current;

    if (lightboxContainer) {
      lightboxContainer.addEventListener('touchstart', handleTouchStart);
      lightboxContainer.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (lightboxContainer) {
        lightboxContainer.removeEventListener('touchstart', handleTouchStart);
        lightboxContainer.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [nextSlide, prevSlide, lightboxRef]);

  useEffect(() => {
    const cat = [...new Set(data.products.map((element) => element.category))];
    setCategory(cat);
  }, []);

  const isDesktop = window.innerWidth > 700;

  return (
    <div className={`mobile${isDesktop ? ' desktop' : ''}`}>
      <h3>Categories:</h3>
      <div className='categories-btn'>
        {category?.map((el) => (
          <button onClick={() => filterByCat(el)} key={el}>{el}</button>
        ))}
        <button onClick={() => setProducts(data.products)}>Te gjitha</button>
      </div>
      <div className='products'>
        {products.map((el) => (
          <div key={el.id} className='card-product'>
            <img
              src={Array.isArray(el.images) ? el.images[0] : el.images}
              alt=''
              onClick={() => openLightbox(el.id)}
            />
          </div>
        ))}
      </div>

      {lightboxImage && (
        <div className="lightbox-container" ref={lightboxRef}>
          {isDesktop && <button className="slider-btn" onClick={prevSlide}>&lt;</button>}
          <span className="close-btn" onClick={closeLightbox}>&times;</span>
          <img className="lightbox-content" src={lightboxImage} alt="Lightbox Img" />
          {isDesktop && <button className="slider-btn" onClick={nextSlide}>&gt;</button>}
        </div>
      )}
    </div>
  );
};

export default Mobile;
