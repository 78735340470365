import { BrowserRouter, Routes, Route } from "react-router-dom"
import './App.css';
import MobilePage from "./Pages/MobilePage";
import Footer from "./Components/Footer/Footer";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MobilePage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
