import React from 'react';
import "./footer.scss"
import image0 from '../../assets/images/0.jpg'
import facebook from '../../assets/images/f1.png'

const Footer = () => {
  return (
    <div className="footer">
      <img src={image0} alt="" />
        <div>
        <ul>
        <li>
         NA VIZITONI NE:
        </li>
        <li>
         ZHUR, PRIZREN
        </li>
        <li>
          (Rruga per Dragash)
        </li>
        <li>
         <a href="https://www.facebook.com/mobileriaextra"><img className='facebook' src={facebook} alt="Facebook" /></a>
        </li>
        </ul>
      </div>
      <div>
      <ul>
        <li>
          POROSITNI TANI
        </li>
        <li>
          Tel :
        </li>
        <li>
          044 113 352
        </li>
        <li>
         044 237 353
        </li>
        <li>
         044 818 307
        </li>
        </ul>
      </div>
    </div>
  )
};

export default Footer;
