import React from 'react'
import Mobile from '../Components/Mobile/Mobile';
import Header from "../Components/Header/Header";

const MobilePage = () => {
  return (
    <div>
      <Header title="Mirë se erdhët" />
      <Mobile />
    </div>
  )
};

export default MobilePage;
