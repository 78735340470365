import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import "./header.scss";
import image0 from '../../assets/images/s0.jpg';
import image1 from '../../assets/images/s1.jpeg';
import image2 from '../../assets/images/s2.jpeg';
import image3 from '../../assets/images/s3.jpeg';

const Header = (props) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const images = [image0, image1, image2, image3];
  const texts = [
    'Mobileria EXTRA, me një traditë shumë vjeqare, bën prodhimin e mobiljeve, dhomave të gjumit, dhomave për femijë, tavolinave të bukës dhe kuzhinave të të gjitha llojeve të ndryshme, sipas kërkesës dhe dimenzioneve të hapësirës.',
    "Një zgjedhje e gjerë e graniturave me modele unike dhe çmimet me te mira ne treg.",
    "Në mobilerin EXTRA mund të gjeni tavolina buke të modeleve të ndryshme. Gjithashtu mund te porositni tavolinen dhe karrige sipas modelit dhe kerkeses tuaj.",
    "MOBILERIA EXTRA me një traditë shumë vjeqare në prodhimin e kuzhinave ju prezanton disa nga shumë projektet e përfunduara te klentët tonë besnik. Ejani dhe porositni kuzhinën tuaj sipas modelit, kërkesës dhe dimenzioneve te vendit."
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSliderIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="header">
      <div className="slider-content">
        <h1>{props.title}</h1>
        <p>{texts[sliderIndex]}</p>
      </div>
      <img className='image' src={images[sliderIndex]} alt={`Slide ${sliderIndex + 1}`} />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
